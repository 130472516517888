import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct import for your Firebase config
import './AdminGeneralInquiries.css';

function AdminGeneralInquiries() {
  const [generalInquiries, setGeneralInquiries] = useState([]);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGeneralInquiries = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(firestore, 'general-inquiries'), orderBy('timestamp', 'desc'))
        );
        const inquiries = querySnapshot.docs.map((docSnapshot) => ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        }));

        // Sort inquiries: unresponded first, then by most recent timestamp
        inquiries.sort((a, b) => {
          const timestampA = a.timestamp ? a.timestamp.toDate() : new Date(0);
          const timestampB = b.timestamp ? b.timestamp.toDate() : new Date(0);

          if (a.responded === b.responded) {
            return timestampB - timestampA; // Most recent first
          }
          return a.responded ? 1 : -1; // Unresponded first
        });

        setGeneralInquiries(inquiries);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching general inquiries:', error);
      }
    };

    fetchGeneralInquiries();
  }, []);

  const handleInputChange = (field, value) => {
    setSelectedInquiry((prevInquiry) => ({
      ...prevInquiry,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    if (!selectedInquiry) return;
    try {
      const inquiryRef = doc(firestore, 'general-inquiries', selectedInquiry.id);
      await updateDoc(inquiryRef, selectedInquiry);
      // console.log('Inquiry updated successfully');

      // Update local state
      setGeneralInquiries((prevInquiries) =>
        prevInquiries.map((inquiry) =>
          inquiry.id === selectedInquiry.id ? selectedInquiry : inquiry
        )
      );
    } catch (error) {
      console.error('Error updating inquiry:', error);
    }
  };

  return (
    <div className="admin-general-inquiries">
      <h2>General Inquiries</h2>
      <div className="content">
        {/* Table Section */}
        <div className="inquiries-table">
          {loading ? (
            <p>Loading inquiries...</p>
          ) : generalInquiries.length === 0 ? (
            <p>No general inquiries available</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Responded</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {generalInquiries.map((inquiry) => (
                  <tr
                    key={inquiry.id}
                    onClick={() => setSelectedInquiry(inquiry)}
                    className={selectedInquiry?.id === inquiry.id ? 'selected' : ''}
                  >
                    <td>{inquiry.email}</td>
                    <td>{inquiry.message}</td>
                    <td>{inquiry.responded ? 'Yes' : 'No'}</td>
                    <td>
                      {inquiry.timestamp
                        ? inquiry.timestamp.toDate().toLocaleString()
                        : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Form Section */}
        {selectedInquiry && (
          <div className="inquiry-form">
            <h3>Edit Inquiry</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label>Email:</label>
                <input type="text" value={selectedInquiry.email} readOnly />
              </div>
              <div>
                <label>Message:</label>
                <textarea
                  value={selectedInquiry.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                />
              </div>
              <div>
                <label>Responded:</label>
                <select
                  value={selectedInquiry.responded ? 'Yes' : 'No'}
                  onChange={(e) => handleInputChange('responded', e.target.value === 'Yes')}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <button onClick={handleSave}>Save</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminGeneralInquiries;
