import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import BiggerProdCard from "./BiggerProdCard";
import ProductForm from "./ProductForm";

function ProductDetail() {
  const { id } = useParams(); // Get product ID from URL
  const location = useLocation();
  const [product, setProduct] = useState(location.state || null);
  const [loading, setLoading] = useState(!location.state);

  useEffect(() => {
    if (!location.state) {
      const fetchProduct = async () => {
        setLoading(true);
        try {
          const docRef = doc(firestore, "products-test", id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setProduct(docSnap.data());
          } else {
            console.error("Product not found");
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchProduct();
    }
  }, [id, location.state]);

  // Log product to the console for debugging
  useEffect(() => {
    if (product) {
      // console.log("Product Details: ", product);
    }
  }, [product]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <div className="product-detail">
      <BiggerProdCard product={product} />
      <ProductForm productId={id} />
    </div>
  );
}

export default ProductDetail;
