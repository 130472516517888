import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import Home from "./components/Home";
import ProductList from "./components/ProductList";
import ProductDetail from "./components/ProductDetail";
import Contact from "./components/Contact";
import About from "./components/About";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminProductList from "./components/Admin/AdminProductList";
import AdminAddProduct from "./components/Admin/AdminAddProduct";
import AdminGenInquiries from "./components/Admin/AdminGenInquiries";
import AdminProdInquiries from "./components/Admin/AdminProdInquiries";
import AdminDisplayList from "./components/Admin/AdminDisplayList";
import AdminAddDisplay from "./components/Admin/AdminAddDisplay";
import AdminProductTypeList from "./components/Admin/AdminProductTypeList";
import AdminAddProductType from "./components/Admin/AdminAddProductType";

const AppRoutes = ({ user, setShowNavbar }) => {
  const location = useLocation();

  // Hide navbar on home routes
  React.useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home") {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location, setShowNavbar]);

  return (
    <Routes>
      {/* Protected Admin Routes */}
      <Route path="/admin" element={<AdminDashboard user={user} />} />
      <Route path="/admin/products/add" element={<AdminAddProduct />} />
      <Route path="/admin/products" element={<AdminProductList />} />
      <Route path="/admin/product-type" element={<AdminProductTypeList />} />
      <Route path="/admin/product-type/add" element={<AdminAddProductType />} />
      <Route path="/admin/product-inquiries" element={<AdminProdInquiries />} />
      <Route path="/admin/general-inquiries" element={<AdminGenInquiries />} />
      <Route path="/admin/display" element={<AdminDisplayList />} />
      <Route path="/admin/display/add" element={<AdminAddDisplay />} />

      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/products" element={<ProductList />} />
      <Route path="/products/:id" element={<ProductDetail />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
};

// Define PropTypes
AppRoutes.propTypes = {
  user: PropTypes.object, // Adjust this based on the actual structure of user
  setShowNavbar: PropTypes.func.isRequired,
};

export default AppRoutes;
