import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { addDoc, collection, Timestamp, doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProductForm.css";

function ProductForm({ productId }) {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [product, setProduct] = useState(null);
  const [quotedPrice, setQuotedPrice] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRef = doc(firestore, "products-test", productId);
        const productDoc = await getDoc(productRef);

        if (productDoc.exists()) {
          const productData = productDoc.data();
          setProduct(productData);
          setQuotedPrice(productData.price);
        } else {
          // console.log("Product not found.");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const inquiryData = {
        email: email,
        productId: productId,
        message: message,
        responded: false,
        quotedPrice: quotedPrice,
        soldPrice: null,
        sold: false,
        timestamp: Timestamp.fromDate(new Date()),
      };

      await addDoc(collection(firestore, "prod-inquiries"), inquiryData);
      toast.success("Message sent successfully!");
      setMessage("");
      setEmail("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("There was an error sending your message.");
    } finally {
      setIsSending(false);
    }
  };

  if (!product) {
    return <p>Loading product details...</p>;
  }

  return (
    <div className="contact-admin">
      <ToastContainer position="top-center" autoClose={5000} />
      <h2>Interested? Send us a message!</h2>
      <form onSubmit={handleSendMessage}>
        <div className="form-group">
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            placeholder="Write your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isSending}>
          {isSending ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
}

ProductForm.propTypes = {
  productId: PropTypes.string.isRequired, // Validate productId as a required string
};

export default ProductForm;
